import { Controller } from 'stimulus'
import Swiper, {Navigation} from 'swiper'
Swiper.use([Navigation])

export default class extends Controller {
  static targets = ['slider', 'prev', 'next']

  connect() {
    this.initDiningSlider()
    let image = this.sliderTarget.querySelector('img')
    image.addEventListener('load', this.initDiningSlider.bind(this))
  }

  initDiningSlider() {
    new Swiper(this.sliderTarget, {
      slidesPerView: 1.05,
      speed: 600,
      autoHeight: true,
      centeredSlides: false,
      spaceBetween: 20,
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },
      breakpoints: {
        1024: {
          slidesPerView: 2.5,
          lazyPreloadPrevNext: 2,
          centeredSlides: false,
        },
      },
    })
  }

}
